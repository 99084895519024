<script>

export default {
  components: {
  
  },
  data() {
    return {
      equityMetaData: []
    };
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    composition:{
      type: Array,
      required: true
    }
  },
  created() {
    this.handleModel(this.model);

    this.equityMetaData = [
      {
        name: "assetClass",
        title: "Asset Class",
        solutionParameters: this.assetClassSolutionParameters
      },
      {
        name: "region",
        title: "Region",
        solutionParameters: this.regionSolutionParameters
      },
      {
        name: "fundamentals",
        title: "Fundamentals",
        solutionParameters: this.fundamentalsSolutionParameters
      },
      {
        name: "sector",
        title: "Sector",
        solutionParameters: this.sectorSolutionParameters
      }
    ];
  },
  watch: { 
    model: function(newVal) { 
       this.handleModel(newVal);
    },
    assetClassSolutionParameters(newVal) {
      const assetClassItem = this.equityMetaData.find(item => item.name === 'assetClass');
      if (assetClassItem) {
        assetClassItem.solutionParameters = newVal;
      }
    },
    regionSolutionParameters(newVal) {
      const regionItem = this.equityMetaData.find(item => item.name === 'region');
      if (regionItem) {
        regionItem.solutionParameters = newVal;
      }
    },
    fundamentalsSolutionParameters(newVal) {
      const fundamentalsItem = this.equityMetaData.find(item => item.name === 'fundamentals');
      if (fundamentalsItem) {
        fundamentalsItem.solutionParameters = newVal;
      }
    },
    sectorSolutionParameters(newVal) {
      const sectorItem = this.equityMetaData.find(item => item.name === 'sector');
      if (sectorItem) {
        sectorItem.solutionParameters = newVal;
      }
    }
  },
  computed:{
    solutionParametersNoEquityMetaData() {
      return this.model.parameters.filter(param => 
        param.scope === 'PerSolution' && 
        !this.assetClassSolutionParameters.includes(param) && !this.regionSolutionParameters.includes(param) && 
        !this.fundamentalsSolutionParameters.includes(param) && !this.sectorSolutionParameters.includes(param)
      )
    },
    filteredEquityMetaData() {
      return this.equityMetaData.filter(item => item.solutionParameters.length > 0);
    },
    assetClassSolutionParameters() {
      if(this.model) {
        return this.model.parameters.filter(x => x.solutionParameterName.startsWith('AssetClass'));

      } else {
        return [];
      }
    },
    regionSolutionParameters() {
      if(this.model) {
        return this.model.parameters.filter(x => x.solutionParameterName.startsWith('Region'));

      } else {
        return [];
      }
    },
    fundamentalsSolutionParameters() {
      if(this.model) {
        return this.model.parameters.filter(x => x.solutionParameterName.startsWith('Fundamentals'));
      } else {
        return [];
      }
    },
    sectorSolutionParameters() {
      if(this.model) {
        return this.model.parameters.filter(x => x.solutionParameterName.startsWith('SectorL1'));
      } else {
        return [];
      }
    }
  },
  methods:{
    getOptimizationParametersPerPosition() {
      const a = this.model.parameters.filter(x => x.scope !== 'PerSolution').map(x => x.solutionParameterId);
      const b = [...new Set(a)];
      const c = b.map(x =>  this.model.parameters.find(e => e.solutionParameterId === x));
      return c;
    },
    handleModel() {
      
    },
    formatToPercentage(v) {
      return parseFloat((v*100).toFixed(1));
    },
    solutionParameterPairs(solutionParameters) {
      const pairs = {};
      solutionParameters.forEach((x) => {
        const type = x.solutionParameterName.split('_')[2];
        const key = x.solutionParameterTitle;
        if (!pairs[key]) {
          pairs[key] = {};
        }
        pairs[key][type] = x;
      });
      return pairs;
    },
    subClassContainsOverride(solutionParameters) {
      return solutionParameters.some(param => param.solutionParameterName.includes('Override'));
    }
  }
};
</script>

<template>
  <div>
    <dl class="row">
      <dt class="col-lg-3 col-md-5">{{$t('pages.indexView.optimization.startDate')}}</dt>
      <dd class="col-lg-9 col-md-7">{{model.startDate | moment("ddd, MMM Do YYYY")}}</dd>
      <dt class="col-lg-3 col-md-5">{{$t('pages.indexView.optimization.endDate')}}</dt>
      <dd class="col-lg-9 col-md-7">{{model.endDate | moment("ddd, MMM Do YYYY")}}</dd>
    </dl>

    <dl class="row mt-2">
      <dt class="col-lg-3 col-md-5">{{$t('pages.indexView.optimization.portfolioSulition')}}</dt>
      <dd class="col-lg-9 col-md-7">
        {{model.solutionTitle}}
      </dd>
      <template v-for="p in solutionParametersNoEquityMetaData">
        <dt class="col-lg-3 col-md-5" :key="p.id">{{p.solutionParameterTitle}}</dt> 
        <dd class="col-lg-9 col-md-7" :key="p.id">
          <span v-if="p.type !== 'Index'">
            {{p.formattedValue}}
          </span>
          <span v-if="p.type === 'Index'">
            {{p.stringValue}}
          </span>
        </dd>
      </template>
      <template v-for="subClass in filteredEquityMetaData">
        <dt class="col-lg-3 col-md-5" :key="subClass.name">{{subClass.title}}</dt>
        <dd class="col-lg-9 col-md-7" :key="subClass.name + '1'">
          <table class="table table-sm">
            <thead class="thead-dark">
              <tr>
                <th>{{ subClass.title }}</th>
                <!-- <th style="width:30px"></th> -->
                <th>Min</th>
                <th>Max</th>
                <th v-if="subClassContainsOverride(subClass.solutionParameters)">Override</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(pair, solutionParameterName) in solutionParameterPairs(subClass.solutionParameters)" :key="solutionParameterName">
                <td>
                  <span class="mr-1">{{ solutionParameterName }}</span>
                </td>
                <!-- <td style="width:30px"></td> -->
                <td v-for="(param, paramName) in { 'Min': pair.Min, 'Max': pair.Max, 'Override': pair.Override }" :key="paramName">
                  <span v-if="paramName === 'Override' ? pair.Override !== undefined : true">
                    {{formatToPercentage(param.formattedValue)}}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </dd>
      </template>
      <template v-for="childIndex in composition">
        <dt class="col-lg-3 col-md-5 pl-4" style="font-weight:400"  :key="childIndex.id">
          {{childIndex.childName}}
          <small v-if="childIndex.minDate" class="d-block">
            <span class="font-bold">Start Date: {{childIndex.minDate | moment('LL')}}</span>
          </small>
        </dt>
        <dd class="col-lg-9 col-md-7" :key="childIndex.id">
          <table class="table table-sm" v-if="getOptimizationParametersPerPosition().length > 0">
            <thead class="thead-dark">
              <tr>
                <th style="width:30px"></th>
                <th v-for="i in getOptimizationParametersPerPosition()" :key="i.solutionParameterId">{{i.solutionParameterTitle}}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width:30px"></td>
                <td v-for="i in  getOptimizationParametersPerPosition()" :key="i.solutionParameterId">
                  <span v-if="i.type === 'Index'">
                    {{(model.parameters.find(x => x.solutionParameterId === i.solutionParameterId && x.indexId === childIndex.childId) || {}).stringValue}}
                  </span>
                  <span v-if="i.type !== 'Index'">
                    {{formatToPercentage((model.parameters.find(x => x.solutionParameterId === i.solutionParameterId && x.indexId === childIndex.childId) || {}).value || 0)}}
                  </span>
                  
                </td>
              </tr>
            </tbody>
          </table>
        </dd>
      </template>
      
    </dl>
    <div class="card-title mt-4">
      
    </div>
    <dl class="row">
      <dt class="col-lg-3 col-md-5"><input type="checkbox" :disabled="true" class="mr-2"  v-model="model.walkForwardOptimizationEnabled"/>  {{$t('pages.indexView.optimization.walkForwardOptimization')}}</dt>
      <dd class="col-lg-9 col-md-7"></dd>
      <dt class="col-lg-3 col-md-5" style="font-weight:400">{{$t('pages.indexView.optimization.walkForwardOptimization_inSamplePeriod')}}</dt>
      <dd class="col-lg-9 col-md-7">{{(model.walkForwardOptimization || {}).inSamplePeriod || 0}}</dd>
      <dt class="col-lg-3 col-md-5" style="font-weight:400">{{$t('pages.indexView.optimization.walkForwardOptimization_periodBetweenRebalancing')}}</dt>
      <dd class="col-lg-9 col-md-7">{{(model.walkForwardOptimization || {}).periodBetweenRebalancing || 0}}</dd>
    </dl>

    <dl class="row mt-4">
      <dt class="col-lg-3 col-md-5"><input type="checkbox" :disabled="true" class="mr-2" v-model="model.targetVolatilityEnabled"/>{{$t('pages.indexView.optimization.targetVolatility')}}:</dt>
      <dd  class="col-lg-9 col-md-7">{{model.targetVolatility || 0}} </dd>
    
    </dl>    
  </div>

</template>