<script>
import http from '@/oauth-client'
import moment from 'moment'
import FilterCard from "@/views/pages/indices/filter-cardv2";
import simplebar from "simplebar-vue";

import {
   
} from "@/state/helpers";


// import 'bootstrap/scss/bootstrap.scss';
import {
   authComputed
} from "@/state/helpers";

export default {
  components: {
    FilterCard,
    simplebar
  },
  data() {
    
    return {
      fieldsMap:{
        'name': this.$t('tables.indices.columns.name'),
        'index-type': this.$t('tables.indices.columns.indexType'),
        'market': this.$t('tables.indices.columns.market'),
        'provider':this.$t('tables.indices.columns.provider'),
        'asset-class': this.$t('tables.indices.columns.assetClass'),
        'instrument-type': this.$t('tables.indices.columns.instrumentType'),
        'rebalance-frequency': this.$t('tables.indices.columns.rebalanceFrequency'),
        'ytd': this.$t('tables.indices.columns.ytd'),
        'mtd': this.$t('tables.indices.columns.mtd'),
        'annualised-return': this.$t('tables.indices.columns.annualisedReturn'),
        'realised-volatility': this.$t('tables.indices.columns.realisedVolatility'),
        'downside-volatility': this.$t('tables.indices.columns.downsideVolatility'),
        'max-drawdown': this.$t('tables.indices.columns.maxDrawdown'),
        'winning-months': this.$t('tables.indices.columns.winningMonths'),
        'sharpe-ratio': this.$t('tables.indices.columns.sharpeRatio'),
        'sortino-ratio': this.$t('tables.indices.columns.sortinoRatio'),
        'calmar-ratio': this.$t('tables.indices.columns.calmarRatio')
      },
      mainChartYAxisLogarithmicType: false,

      filtersData: null,
      filterCriteria: null,

      items:[],

      composeIndexItems: [],
  
      initializing: true,
      initialized: false,

      viewMode: 'grid',
     
      statColumns: {
        market:{
          visible: true
        },
        provider:{
          visible: true
        },
        indexType:{
          visible: true
        },
        indexSubType:{
          visible: true
        },
        statUpdatedAt:{
          visible: true
        },
        instrumentType:{
          visible: true
        },
        assetClass:{
          visible: true
        },
        rebalanceFrequency:{
          visible: true
        },
        mtd:{
          visible: true
        },
        ytd:{
          visible: true
        },

        annualisedReturn:{
          visible: true
        },
        realisedVolatility:{
          visible: true
        },
        downsideVolatility:{
          visible: true
        },
        maxDrawdown:{
          visible: true
        },
        winningMonths:{
          visible: true
        },
        sharpeRatio:{
          visible: true
        },
        sortinoRatio:{
          visible: true
        },
        calmarRatio:{
          visible: true
        },
      },
    

      dictionary:{
        rebalanceFrequencyMap:{

        },
        assetClassMap:{

        },
        instrumentTypeMap: {

        },
      },

      filters:[

      ],
      isBusy: false,

      keysLoading: false,
   
      keys:[],
  
      imageUrl: null,
      imageVisible: false,

      criteria: this.defaultCriteria(),

      productQuery: null,
      productsLoading: false,
      products:[],

      portfolioSolutions: [],
     
      currentPage: 1,
      perPage: 20,
      totalItems: 0,
    };
  },
  created() {
    this.filters.push({
      nameAll: 'Select All',
      nameNotAll: 'Reset',
        func(elem) {
          return elem.selected;
        }
    });
    
    this.init();
    
  },
  watch: {
    mainChartYAxisLogarithmicType:{
      handler: function () {
        if(this.initializing === true) return;
        this.rebuildNetAssetValueGrowthCharts();
        
      }
    },
   'criteria.normalisedPerformance': {
      handler: function () {
        if(this.initializing === true) return;
        this.load();
      }
    },
    currentPage: {
      handler: function () {
        if(this.initializing === true) return;
      
        this.load();
      }
    },
    perPage: {
       handler: function () {
        if(this.initializing === true) return;
        this.load();
      }
    }
  },
  computed: {
    ...authComputed,
    filteredItems() {
      return this.items;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.perPage);
    },
    isAdmin() {
      return this.currentUser.isAdmin;
    },
   
    isFilterEnabled() {
      if(this.isStatFiltered) return true;
      if(this.criteria.composedIndex) return true;
      if((this.criteria.portfolioSolution || []).length > 0) return true;
      if(this.criteria.assetClass.length > 0) return true;
      if(this.criteria.instrumentType.length > 0) return true;
      if(this.criteria.indexType.length > 0) return true;
      if(this.criteria.rebalanceFrequency.length > 0) return true;
      if(this.criteria.market.length > 0) return true;
      if(this.criteria.keyword) return true;
      if(this.criteria.provider.length > 0) return true;

      return false;
    },
    isStatFiltered() {
      if(this.criteria.mtd.from !== null && this.criteria.mtd.from !== undefined) return true;
      if(this.criteria.mtd.till !== null && this.criteria.mtd.till !== undefined) return true;
      
      if(this.criteria.ytd.from !== null && this.criteria.ytd.from !== undefined) return true;
      if(this.criteria.ytd.till !== null && this.criteria.ytd.till !== undefined) return true;

      if(this.criteria.annualisedReturn.from !== null && this.criteria.annualisedReturn.from !== undefined) return true;
      if(this.criteria.annualisedReturn.till !== null && this.criteria.annualisedReturn.till !== undefined) return true;

      if(this.criteria.realisedVolatility.from !== null && this.criteria.realisedVolatility.from !== undefined) return true;
      if(this.criteria.realisedVolatility.till !== null && this.criteria.realisedVolatility.till !== undefined) return true;

      if(this.criteria.downsideVolatility.from !== null && this.criteria.downsideVolatility.from !== undefined) return true;
      if(this.criteria.downsideVolatility.till !== null && this.criteria.downsideVolatility.till !== undefined) return true;
      
      if(this.criteria.maxDrawdown.from !== null && this.criteria.maxDrawdown.from !== undefined) return true;
      if(this.criteria.maxDrawdown.till !== null && this.criteria.maxDrawdown.till !== undefined) return true;

      if(this.criteria.winningMonths.from !== null && this.criteria.winningMonths.from !== undefined) return true;
      if(this.criteria.winningMonths.till !== null && this.criteria.winningMonths.till !== undefined) return true;

      if(this.criteria.sharpeRatio.from !== null && this.criteria.sharpeRatio.from !== undefined) return true;
      if(this.criteria.sharpeRatio.till !== null && this.criteria.sharpeRatio.till !== undefined) return true;

      if(this.criteria.sortinoRatio.from !== null && this.criteria.sortinoRatio.from !== undefined) return true;
      if(this.criteria.sortinoRatio.till !== null && this.criteria.sortinoRatio.till !== undefined) return true;

      if(this.criteria.calmarRatio.from !== null && this.criteria.calmarRatio.from !== undefined) return true;
      if(this.criteria.calmarRatio.till !== null && this.criteria.calmarRatio.till !== undefined) return true;

      return false;
    }
  },
  methods: {
    parseDatePicker(v){
      const o = moment(v, 'YYYY-MM-DD');
      return o.format('DD/MM/YYYY');
    },
    onFilterApply (criteria) {
      this.filterCriteria = criteria;
      this.currentPage = 1;
      this.load();
    },
    async loadFilters() {
      const resp = await http.get( 'api/indices/filters');
      this.filtersData = resp.data;

      let rebalanceFrequencyMap = {};
      let assetClassMap = {};
      let instrumentTypeMap = {};
      resp.data.rebalanceFrequency.forEach(x => rebalanceFrequencyMap[x.value] = x.text );
      resp.data.instrumentType.forEach(x => instrumentTypeMap[x.value] = x.text );
      resp.data.assetClass.forEach(x => assetClassMap[x.value] = x.text );
      this.rebalanceFrequencyMap = rebalanceFrequencyMap;
      this.assetClassMap = assetClassMap;
      this.instrumentTypeMap = instrumentTypeMap;
    },
    defaultCriteria(){
      return  {
        normalisedPerformance: false,
        sortBy: 'name',
        sortByDir: 'asc',
      };
    },
    resetCriteria() {
     this.criteria = this.defaultCriteria();
     this.applyFilters();
   },
    async init() {
      this.isBusy = true;
      this.initializing = true;

      await this.loadPortfolioSolutions();
      await this.loadFilters();

      this.initialized = true;
    },
    async loadPortfolioSolutions() {
      const resp = await http.get('api/portfolio-solutions');
      this.portfolioSolutions = resp.data;
    },
    selectAllComposeIndexPart() {
      const newItems = this.items.filter(i => !this.composeIndexItems.find(a => a.id === i.id));
      const prepNewItems = newItems.map(i => this.prepareObjFroComposeIndex(i));
      let v = [... this.composeIndexItems, ...prepNewItems];
      v.sort((a, b) => (a.displayName || '').localeCompare(b.displayName || ''));

      if(v.length > 100) {
        v = v.slice(0, 100);
      }

      this.composeIndexItems = v;
    },
    prepareObjFroComposeIndex(i) {
      let minDate = null;
      (i.netAssetValueGrowthCharts || []).forEach(chartData =>{
        (chartData.points || []).forEach(point => {
          const dt = moment(point.dateTime);
          if(minDate === null || minDate > dt) {
            minDate = dt;
          }
        });
      });
      return { id: i.id, displayName: i.displayName, minDate: (minDate ? minDate.toDate() : null) };
    },
    selectComposeIndexPart(i) {
      if(this.composeIndexItems.find(a => a.id === i.id)) {
        return;
      }
     
      let v =[... this.composeIndexItems, this.prepareObjFroComposeIndex(i)];
      v.sort((a, b) => a.displayName.localeCompare(b.displayName));
      
       if(v.length > 100) {
        v = v.slice(0, 100);
      }

      this.composeIndexItems = v;
    },
    removeComposeIndexPart(i){
      let v =[... this.composeIndexItems.filter(a => a.id !== i.id)];
      v.sort((a, b) => a.displayName.localeCompare(b.displayName));
      this.composeIndexItems = v;
    },
   
    async applyFilters(){
  
      if( this.currentPage != 1){
        this.currentPage = 1;
      } else {
        this.load();
      }
    },

    async loadKeys(){
    
      this.selectedKeyValue = null;
      this.selectedKey = null;
      const resp = await http.get(`api/indices/system-values/keys`);
      this.keys = resp.data;
    
    },
    prepareNetAssetValueGrowthCharts(data) {
     const colors = ['#434348', '#7cb5ec', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'];
      let i = 0;
    
     let chartSeries =  data.netAssetValueGrowthCharts;
      if(!chartSeries)
          return;

      chartSeries.forEach(x => x.chartVisible = true);
      chartSeries
        .filter(x => x.indexId === data.id && !x.isLive)
        .forEach(x => x.color = "#FE6C2D");
      chartSeries
        .filter(x => x.indexId === data.id && x.isLive)
        .forEach(x => x.color = "#7d096d");

      chartSeries
        .filter(x => x.indexId !== data.id)
        .forEach(x => {
          x.color = colors[i++];
          if(i === colors.length) i = 0;
        });
    },

    createChartOptions(element) {
      let chartSeries =  element.netAssetValueGrowthCharts || [];
      if(!chartSeries)
          return;
        
      let seriesOptions = [];
      for(let i = 0; i < chartSeries.length; i++) {
        const source = chartSeries[i];
        if(source.indexId === element.id)
        {
          seriesOptions.push({
            name: source.title + (source.isLive ? ' [Live]' : ''),
            label: source.title,
            data: (source.points || []).map(x => [x.timeStamp, x.value]),
            color: source.color,
            lineWidth: source.isLive ? 3 : 1
          });
        }
      }

      return {
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        accessibility:{
          enabled: false
        },
        annotations: {
          visible: false
        },
        boost:{
          enabled: false
        },
        exporting:{
          enabled: false
        },
        navigator:{
          enabled: false
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false
            }
          },
        },
         
        yAxis: {
          type: this.mainChartYAxisLogarithmicType ? 'logarithmic' : null,
          labels: {
            formatter: function () {
              return (this.value > 0 ? '' : '') + this.value;
            }
          },
          plotLines: [{
            value: 0,
              width: 2,
              color: 'silver'
          }]
        },
        
        tooltip: {
          pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
          valueDecimals: 2
        },
        colors: ['#434348', '#7cb5ec', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'],
        series: seriesOptions
      };
    },

    rebuildNetAssetValueGrowthCharts() {
      this.items.forEach(x => {
        x.chartOptions = this.createChartOptions(x);
      });
    },
  
    async load(silence)  {
     
      if(silence !== true) {
        this.isBusy = true;
      }

      const currentPage = this.currentPage;
      let sv = {};
      if(this.criteria.selectedKey){
        sv[this.criteria.selectedKey] = this.criteria.selectedKeyValue;
      }
     
      const dto = {
        take: this.perPage,
        skip: this.perPage * (currentPage - 1),
        keyword:  this.filterCriteria.keyword,
        name: this.filterCriteria.name,
        systemValues:sv,
        providers: (this.filterCriteria.provider || []).map(x => x),
        bookmarksOnly:  this.showOnlyBookmarks || false,
        portfolioSolutions: (this.filterCriteria.portfolioSolution || []).map(x => x),
        markets: this.filterCriteria.market,
        indexTypes:  this.filterCriteria.indexType,
        indexSubTypes: this.filterCriteria.indexSubType,
        instrumentTypes: this.filterCriteria.instrumentType,
        assetClasses: this.filterCriteria.assetClass,
        rebalanceFrequency: this.filterCriteria.rebalanceFrequency,
        sortBy: this.criteria.sortBy,
        sortByDir: this.criteria.sortByDir,
        mtd: this.filterCriteria.mtd,
        ytd: this.filterCriteria.ytd,
        annualisedReturn: this.filterCriteria.annualisedReturn,
        realisedVolatility: this.filterCriteria.realisedVolatility,
        downsideVolatility: this.filterCriteria.downsideVolatility,
        maxDrawdown: this.filterCriteria.maxDrawdown,
        winningMonths:this.filterCriteria.winningMonths,
        sharpeRatio: this.filterCriteria.sharpeRatio,
        sortinoRatio: this.filterCriteria.sortinoRatio,
        calmarRatio: this.filterCriteria.calmarRatio,
        normalisedPerformance: this.criteria.normalisedPerformance,
        singleIndices: this.filterCriteria.singleIndices,
        compositeIndices: this.filterCriteria.compositeIndices
      };
      if(dto.maxDrawdown.from === ''){
        dto.maxDrawdown.from = null;
      } 
      if(dto.maxDrawdown.till === ''){
        dto.maxDrawdown.till = null;
      }
      if(dto.annualisedReturn.from === ''){
        dto.annualisedReturn.from = null;
      } 
      if(dto.annualisedReturn.till === ''){
        dto.annualisedReturn.till = null;
      }
      if(dto.realisedVolatility.from === ''){
        dto.realisedVolatility.from = null;
      } 
      if(dto.realisedVolatility.till === ''){
        dto.realisedVolatility.till = null;
      }
      if(dto.winningMonths.from === ''){
        dto.winningMonths.from = null;
      } 
      if(dto.winningMonths.till === ''){
        dto.winningMonths.till = null;
      }
      if(dto.sharpeRatio.from === ''){
        dto.sharpeRatio.from = null;
      } 
      if(dto.sharpeRatio.till === ''){
        dto.sharpeRatio.till = null;
      }
      if(dto.sortinoRatio.from === ''){
        dto.sortinoRatio.from = null;
      } 
      if(dto.sortinoRatio.till === ''){
        dto.sortinoRatio.till = null;
      }
      if(dto.calmarRatio.from === ''){
        dto.calmarRatio.from = null;
      } 
      if(dto.calmarRatio.till === ''){
        dto.calmarRatio.till = null;
      }
     
      const resp = await http.post(`api/indices/search`, dto);
     
      resp.data.items.forEach(element => {
       
        this.prepareNetAssetValueGrowthCharts(element);
        element.chartOptions = this.createChartOptions(element);
      });

      this.items = resp.data.items;
      this.totalItems = resp.data.total;
     
      if(silence !== true) {
        this.isBusy = false;
      } 
      this.initializing = false;
    },
    
    getImageUrl(item){
      return item ? process.env.VUE_APP_BASE_URL + "/api/indices/" + item.id + "/image" : "/";
    },
    sortByColumn(column){
      if(this.criteria.sortBy === column)
        return;

      this.criteria.sortBy = column;
    
      this.load();
    },
    sortByDir(dir) {
      if(this.criteria.sortByDir === dir)
        return;
      this.criteria.sortByDir = dir;
     
      this.load();
    },
    sortBy(column) {
      if(this.criteria.sortBy === column) {
        if(this.criteria.sortByDir === 'asc') {
          this.criteria.sortByDir = 'desc';
        } else {
          this.criteria.sortByDir = 'asc';
        }
      } else {
        this.criteria.sortBy = column;
        this.criteria.sortByDir = 'asc';
      }
     
      this.load();
    },
    showList(){
      this.viewMode = 'list';
    },
    showGrid(){
      this.viewMode = 'grid';
      this.rebuildNetAssetValueGrowthCharts();
    },
    cancel() {
      this.$emit('cancel');
    },
    apply() {
      this.$emit('apply', this.composeIndexItems);
    }
  }
};
</script>

<template>
  <div v-if="initialized">
    <div class="row">
      <div class="col-xl-3">
        <div class="filter-card"
        >
          <filter-card 
              no-card
              v-if="filtersData"
              :values="filtersData" 
              :persistent="true" 
              @apply="onFilterApply"
              @load="onFilterApply"
              :reset-button-no-action="false"
              apply-button-title="Apply"
            >
          </filter-card> 
        </div>
        
        <div v-if="isBusy" style="position:absolute;left:0; top:0; bottom:0; right:0;background:white;opacity:0.5;"></div>

      </div>

      <div class="col-xl-6">
        <div class="">
          <div>
            <div class="row mb-2">
              <div class="col-md-4 text-truncate no-wrap">
                <div>
                  <b-form-checkbox v-model="criteria.normalisedPerformance" switch class="mr-1" style="display:inline-block;">
                  </b-form-checkbox>{{$t("pages.indexView.normalisedPerformance")}}
                </div>
                <div v-if="viewMode === 'grid'">
                  <b-form-checkbox 
                    v-model="mainChartYAxisLogarithmicType" switch class="mr-1" 
                    style="display:inline-block;">
        
                  </b-form-checkbox>{{$t("pages.indexView.yAxisLogarithmic")}}
                </div>
                
              </div>

              <div class="col-md-8 text-right">
                
                <div class="btn-group mr-2 mb-2 mb-sm-0">
                  <button type="button" class="btn btn-outline" :class="{'btn-outline-primary': viewMode === 'list'}" @click="showList()">
                    <i class="fa fa-list"></i>
                  </button>
                </div>
                <div class="btn-group mr-4 mb-2 mb-sm-0">
                  <button type="button" class="btn btn-outline " :class="{'btn-outline-primary': viewMode === 'grid'}" @click="showGrid()">
                    <i class="fa fa-th"></i>
                    </button>
                </div> 
                <div class="btn-group mr-2 mb-2 mb-sm-0">
                
                  <b-dropdown left variant="white">
                    <template v-slot:button-content>
                      <i class="fa fa-columns m-0 text-muted font-size-20"></i>
                    </template>
                    <div class="dropdown-item bg-white text-body">
                      <div class="form-group"><input type="checkbox" v-model="statColumns.indexType.visible" id="stateColumns_indexType"/>
                        <label for="stateColumns_indexType" class="ml-2">{{$t('tables.indices.columns.indexType')}}</label>
                      </div>
                      <div class="form-group"><input type="checkbox" v-model="statColumns.indexSubType.visible" id="stateColumns_indexSubType"/>
                        <label for="stateColumns_indexSubType" class="ml-2">Index Sub Type</label>
                      </div>
                      <div class="form-group"><input type="checkbox" v-model="statColumns.market.visible" id="stateColumns_market"/>
                        <label for="stateColumns_market" class="ml-2">{{$t('tables.indices.columns.market')}}</label>
                      </div>
                      <div class="form-group"><input type="checkbox" v-model="statColumns.provider.visible" id="stateColumns_provider"/>
                        <label for="stateColumns_provider" class="ml-2">{{$t('tables.indices.columns.provider')}}</label>
                      </div>
                      <div class="form-group"><input type="checkbox" v-model="statColumns.assetClass.visible" id="stateColumns_assetClass"/>
                        <label for="stateColumns_assetClass" class="ml-2">{{$t('tables.indices.columns.assetClass')}}</label>
                      </div>
                      <div class="form-group"><input type="checkbox" v-model="statColumns.instrumentType.visible" id="stateColumns_instrumentType"/>
                        <label for="stateColumns_instrumentType" class="ml-2">{{$t('tables.indices.columns.instrumentType')}}</label>
                      </div>
                      <div class="form-group"><input type="checkbox" v-model="statColumns.rebalanceFrequency.visible" id="stateColumns_rebalanceFrequency"/>
                        <label for="stateColumns_rebalanceFrequency" class="ml-2">{{$t('tables.indices.columns.rebalanceFrequency')}}</label>
                      </div>
                      <div class="form-group"><input type="checkbox" v-model="statColumns.ytd.visible" id="stateColumns_ytd"/>
                        <label for="stateColumns_ytd" class="ml-2">{{$t('tables.indices.columns.ytd')}}</label>
                      </div>
                      <div class="form-group"><input type="checkbox" v-model="statColumns.mtd.visible" id="stateColumns_mtd"/>
                        <label for="stateColumns_mtd" class="ml-2">{{$t('tables.indices.columns.mtd')}}</label>
                      </div>
                      <div class="form-group"><input type="checkbox" v-model="statColumns.statUpdatedAt.visible" id="stateColumns_statUpdatedAt"/>
                        <label for="stateColumns_statUpdatedAt" class="ml-2">Updated At</label>
                      </div>
                      <div class="form-group"><input type="checkbox" v-model="statColumns.annualisedReturn.visible" id="stateColumns_annualisedReturn"/>
                        <label for="stateColumns_annualisedReturn" class="ml-2">{{$t('tables.indices.columns.annualisedReturn')}}</label>
                      </div>
                      <div class="form-group"><input type="checkbox" v-model="statColumns.realisedVolatility.visible" id="stateColumns_realisedVolatility"/>
                        <label for="stateColumns_realisedVolatility" class="ml-2">{{$t('tables.indices.columns.realisedVolatility')}}</label>
                      </div>
                      <div class="form-group"><input type="checkbox" v-model="statColumns.downsideVolatility.visible" id="stateColumns_downsideVolatility"/>
                        <label for="stateColumns_downsideVolatility" class="ml-2">{{$t('tables.indices.columns.downsideVolatility')}}</label>
                      </div>
                      <div class="form-group"><input type="checkbox" v-model="statColumns.maxDrawdown.visible" id="stateColumns_maxDrawdown"/>
                        <label for="stateColumns_maxDrawdown" class="ml-2">{{$t('tables.indices.columns.maxDrawdown')}}</label>
                      </div>
                      <div class="form-group"><input type="checkbox" v-model="statColumns.winningMonths.visible" id="stateColumns_winningMonths"/>
                        <label for="stateColumns_winningMonths" class="ml-2">{{$t('tables.indices.columns.winningMonths')}}</label>
                      </div>
                      <div class="form-group"><input type="checkbox" v-model="statColumns.sharpeRatio.visible" id="stateColumns_sharpeRatio"/>
                        <label for="stateColumns_sharpeRatio" class="ml-2">{{$t('tables.indices.columns.sharpeRatio')}}</label>
                      </div>
                      <div class="form-group"><input type="checkbox" v-model="statColumns.sortinoRatio.visible" id="stateColumns_sortinoRatio"/>
                        <label for="stateColumns_sortinoRatio" class="ml-2">{{$t('tables.indices.columns.sortinoRatio')}}</label>
                      </div>
                      <div class="form-group"><input type="checkbox" v-model="statColumns.calmarRatio.visible" id="stateColumns_calmarRatio"/>
                        <label for="stateColumns_calmarRatio" class="ml-2">{{$t('tables.indices.columns.calmarRatio')}}</label>
                      </div>
                    </div>
                  </b-dropdown>

                  <b-dropdown left variant="white" ref="sortByDropdown">
                    <template v-slot:button-content>
                      <b class="mr-2" style="">{{fieldsMap[criteria.sortBy]}}</b>
                      <i class="fas text-muted fa-sort-alpha-up mr-2" v-if=" criteria.sortByDir === 'asc'"/>
                      <i class="fas text-muted fa-sort-alpha-down mr-2" v-if=" criteria.sortByDir !== 'asc'"/>
                      <i class="fas fa-caret-down"/>
                    </template>
                    <b-dropdown-form>
                      <select class="form-control " 
                        :disabled="isBusy"
                        :value="criteria.sortBy" 
                        style="width:200px" 
                        @change="($event) => sortByColumn($event.target.value)"
                      >
                        <option value="name">Name</option>
                        <option value="index-type">{{$t('tables.indices.columns.indexType')}}</option>
                        <option value="market">{{$t('tables.indices.columns.market')}}</option>
                        <option value="provider">{{$t('tables.indices.columns.provider')}}</option>
                        <option value="asset-class">{{$t('tables.indices.columns.assetClass')}}</option>
                        <option value="instrument-type">{{$t('tables.indices.columns.instrumentType')}}</option>
                        <option value="rebalance-frequency">{{$t('tables.indices.columns.rebalanceFrequency')}}</option>
                        <option value="ytd">{{$t('tables.indices.columns.ytd')}}</option>
                        <option value="mtd">{{$t('tables.indices.columns.mtd')}}</option>
                        <option value="stat-updated">Updated At</option>
                        <option value="annualised-return">{{$t('tables.indices.columns.annualisedReturn')}}</option>
                        <option value="realised-volatility">{{$t('tables.indices.columns.realisedVolatility')}}</option>
                        <option value="downside-volatility">{{$t('tables.indices.columns.downsideVolatility')}}</option>
                        <option value="max-drawdown">{{$t('tables.indices.columns.maxDrawdown')}}</option>
                        <option value="winning-months">{{$t('tables.indices.columns.winningMonths')}}</option>
                        <option value="sharpe-ratio">{{$t('tables.indices.columns.sharpeRatio')}}</option>
                        <option value="sortino-ratio">{{$t('tables.indices.columns.sortinoRatio')}}</option>
                        <option value="calmar-ratio">{{$t('tables.indices.columns.calmarRatio')}}</option>
                      </select>
                      <div class="row">
                        <div class="col  mt-2">
                          <button type="button" class="btn btn-block" 
                          :disabled="isBusy"
                          @click="sortByDir('asc')"
                            :class="{' btn-outline-secondary': criteria.sortByDir !== 'asc', ' btn-secondary': criteria.sortByDir === 'asc'}"
                            >
                            <i class="fas fa-sort-alpha-up mr-2"></i>Asc
                          </button>
                        </div>
                        <div class="col mt-2">
                          <button type="button" class="btn btn-outline-secondary btn-block"
                          :disabled="isBusy"
                            @click="sortByDir('desc')"
                          :class="{' btn-outline-secondary': criteria.sortByDir !== 'desc', ' btn-secondary': criteria.sortByDir === 'desc'}"
                            >
                            <i class="fas fa-sort-alpha-down mr-2"></i>Desc
                          </button>
                        </div>
                      </div>
                    </b-dropdown-form>
                    <div class="p-2 pl-4 pr-4">
                      <b-button variant="link" block @click="() => $refs.sortByDropdown.hide(true)">Close</b-button>
                    </div>
                    
                  </b-dropdown>
                </div>

                <div class="btn-group mr-2 mb-2 mb-sm-0">
                </div>
              </div>
            </div>
            <div class="indices-content" v-if="viewMode === 'grid'">
              <div style="height:100%; overflow:auto">
              
                <div class="row no-gutters" >
                  <div class="col-lg-6">
                    <ul class="list-inline my-3 ecommerce-sortby-list" >
                      <li class="list-inline-item">
                        Legend: 
                      </li>
                      <li class="list-inline-item">
                        <div class="badge badge-warning" >Provider</div>
                      </li>
                    <li class="list-inline-item">
                        <div class="badge badge-primary" >Asset class</div>
                      </li>
                      <li class="list-inline-item">
                        <div class="badge badge-success">Instrument type</div>
                      </li>
                      <li class="list-inline-item">
                        <div class="badge badge-secondary"  >Rebalance frequency</div>
                      </li>
                    </ul>
                  </div>
                  <div class="col-lg-6 text-right">
                    
                  </div>
                </div>
                <simplebar style="max-height: 900px; min-height: 300px;" >
                  <div class="row no-gutters">
                    <div class="col-lg-12" v-for="item in items" :key="item.id">
                      <div class="product-box" style="padding-bottom:0">
                      
                        <highcharts v-if="item.chartOptions"
                          :constructorType="'stockChart'"
                          :options="item.chartOptions">
                        </highcharts>
                        <ul class="list-inline my-3 ecommerce-sortby-list" style="position: absolute;top: -10px;left: 5px; right:40px;">
                          <li class="list-inline-item">
                            <div class="badge badge-warning" >{{(item.provider || {}).displayName}}</div>
                          </li>
                          <li class="list-inline-item">
                            <div class="badge badge-primary" >{{assetClassMap[item.assetClass || {}]}}</div>
                          </li>
                          <li class="list-inline-item">
                            <div class="badge badge-success">{{instrumentTypeMap[item.instrumentType || {}]}}</div>
                          </li>
                          <li class="list-inline-item">
                            <div class="badge badge-secondary">{{rebalanceFrequencyMap[item.rebalanceFrequency || {}]}}</div>
                          </li>
                        </ul>
                        <div class="text-center">
                          <p class="font-size-12 mb-1" :title="'Index Type: ' + (item.indexType || {}).displayName">Index type: {{(item.indexType || {}).displayName}}</p>
                          <h5 class="font-size-15">
                            <router-link :to="'/indices/' + item.id + '/view'" class="text-dark">{{item.displayName}}</router-link>
                          </h5>
                          <p class="font-size-12 mt-3 mb-0 text-info" :title="'Market: ' + (item.market || {}).displayName">Market: {{(item.market || {}).displayName}}</p>
                        </div>
                        
                        <table class="mt-4 table table-sm table-hover table-borderless small">
                          <tr v-if="statColumns.ytd.visible || statColumns.mtd.visible">
                            <td>YTD</td>
                            <td class="text-primary"> {{item.performance.ytdFormatted}} <span v-if="item.performance.ytdFormatted">%</span></td>
                            <td>MTD</td>
                            <td class="text-primary"> {{item.performance.mtdFormatted}} <span  v-if="item.performance.mtdFormatted">%</span></td>
                          </tr>
                          <tr v-if="statColumns.annualisedReturn.visible || statColumns.realisedVolatility.visible">
                            <td class="no-wrap">{{$t('tables.indices.columns.annualisedReturn')}}</td>
                            <td class="text-primary font-weight-bold">
                              <span v-if="item.statistic">
                                {{item.statistic.values.annualisedReturnFormatted}} <span  v-if="item.statistic.values.annualisedReturnFormatted">%</span>
                              </span>
                            </td>
                            <td class="no-wrap">{{$t('tables.indices.columns.realisedVolatility')}}</td>
                            <td class="text-primary font-weight-bold">
                              <span v-if="item.statistic">
                                {{item.statistic.values.realisedVolatilityFormatted}} <span  v-if="item.statistic.values.realisedVolatilityFormatted">%</span>
                              </span>
                            </td>
                          </tr>
                          <tr v-if="statColumns.downsideVolatility.visible || statColumns.maxDrawdown.visible">
                            <td class="no-wrap">{{$t('tables.indices.columns.downsideVolatility')}}</td>
                            <td class="text-primary font-weight-bold">
                              <span v-if="item.statistic">
                                {{item.statistic.values.downsideVolatilityFormatted}} <span  v-if="item.statistic.values.downsideVolatilityFormatted">%</span>
                            </span>
                            </td>
                            <td class="no-wrap">
                              {{$t('tables.indices.columns.maxDrawdown')}}
                              </td>
                            <td class="text-primary font-weight-bold">
                              <span v-if="item.statistic">
                                {{item.statistic.values.maxDrawdownFormatted}} <span  v-if="item.statistic.values.maxDrawdownFormatted">%</span>
                              </span>
                            </td>
                          </tr>
                          <tr v-if="statColumns.winningMonths.visible || statColumns.sharpeRatio.visible">
                            <td class="no-wrap">{{$t('tables.indices.columns.winningMonths')}}</td>
                            <td class="text-primary font-weight-bold">
                              <span v-if="item.statistic">
                                {{item.statistic.values.winningMonthsFormatted}} <span  v-if="item.statistic.values.winningMonthsFormatted">%</span>
                              </span>
                            </td>
                            <td class="no-wrap">
                              {{$t('tables.indices.columns.sharpeRatio')}}
                              </td>
                            <td class="text-primary font-weight-bold">
                              <span v-if="item.statistic">
                                {{item.statistic.values.sharpeRatioFormatted}} 
                            </span>
                            </td>
                          </tr>
                          <tr v-if="statColumns.sortinoRatio.visible || statColumns.calmarRatio.visible">
                            <td class="no-wrap">{{$t('tables.indices.columns.sortinoRatio')}}</td>
                            <td class="text-primary font-weight-bold">
                              <span v-if="item.statistic">
                                {{item.statistic.values.sortinoRatioFormatted}} 
                            </span>
                            </td>
                            <td class="no-wrap">
                              {{$t('tables.indices.columns.calmarRatio')}}
                              </td>
                            <td class="text-primary font-weight-bold">
                              <span v-if="item.statistic">
                                {{item.statistic.values.calmarRatioFormatted}} 
                            </span>
                            </td>
                          </tr>
                        </table>
                      
                        <div class=" text-center" style="position: absolute;top: 30%;left:0; right:0; " v-if="!composeIndexItems.find(a => a.id === item.id)">
                          <button style="opacity:0.8" class="btn  btn-lg btn-primary"  @click="selectComposeIndexPart(item)">Select</button>
                        </div>

                      </div>
                    </div>
                  </div>
                </simplebar>
              </div>
            </div>
          
            <div class="indices-content" v-if="viewMode === 'list'">
              <div style="max-height:100%; min-height:300px; overflow:auto">
              
                <table class="table table-centered mb-0 table-nowrap table-sm" >
                  <thead  style="position: sticky; top: 0;z-index:99;">
                    <tr>
                      <th class="text-left favorites" style="width:50px; min-width:50px;">
                      
                        <span> 
                          <button class="btn  btn-sm btn-c8 revers" @click="selectAllComposeIndexPart">
                            <span class="btn-content">
                              Select All
                            </span>
                          </button>
                        </span>

                      </th>
                      
                      <th style="cursor:pointer; min-width:250px;width:300px;" @click="sortBy('name')">
                        <span class="mr-1"> {{$t('tables.indices.columns.name')}}</span>
                      
                        <span class="float-right" v-if="criteria.sortBy === 'name'">
                          <span v-if="criteria.sortByDir === 'asc'">
                            <i class="fa  fas fa-sort-up"></i>
                          </span>
                          <span v-if="criteria.sortByDir === 'desc'">
                            <i class="fa  fas fa-sort-down"></i>
                          </span>
                        </span>
                      </th>
                      <th style="cursor:pointer;min-width: 100px;" @click="sortBy('index-type')" v-if="statColumns.indexType.visible">
                        <span class="mr-1">{{$t('tables.indices.columns.indexType')}}</span>
                        <span class="float-right" v-if="criteria.sortBy === 'index-type'" >
                          <span v-if="criteria.sortByDir === 'asc'">
                            <i class="fa  fas fa-sort-up"></i>
                          </span>
                          <span v-if="criteria.sortByDir === 'desc'">
                            <i class="fa  fas fa-sort-down"></i>
                          </span>
                        </span>
                      </th>
                      <th style="cursor:pointer;min-width: 120px;" @click="sortBy('index-sub-type')" v-if="statColumns.indexSubType.visible">
                        <span class="mr-1">Index Sub Type</span>
                        <span class="float-right" v-if="criteria.sortBy === 'index-sub-type'" >
                          <span v-if="criteria.sortByDir === 'asc'">
                            <i class="fa  fas fa-sort-up"></i>
                          </span>
                          <span v-if="criteria.sortByDir === 'desc'">
                            <i class="fa  fas fa-sort-down"></i>
                          </span>
                        </span>
                      </th>
                      <th style="cursor:pointer;min-width: 80px;" @click="sortBy('market')" v-if="statColumns.market.visible">
                        <span class="mr-1">{{$t('tables.indices.columns.market')}}</span>
                        <span class="float-right" v-if="criteria.sortBy === 'market'" >
                          <span v-if="criteria.sortByDir === 'asc'">
                            <i class="fa  fas fa-sort-up"></i>
                          </span>
                          <span v-if="criteria.sortByDir === 'desc'">
                            <i class="fa  fas fa-sort-down"></i>
                          </span>
                        </span>
                      </th>
                      <th style="cursor:pointer;min-width: 100px;" @click="sortBy('provider')" v-if="statColumns.provider.visible">
                        <span class="mr-1">{{$t('tables.indices.columns.provider')}}</span>
                        <span class="float-right" v-if="criteria.sortBy === 'provider'">
                          <span v-if="criteria.sortByDir === 'asc'">
                            <i class="fa  fas fa-sort-up"></i>
                          </span>
                          <span v-if="criteria.sortByDir === 'desc'">
                            <i class="fa  fas fa-sort-down"></i>
                          </span>
                        </span>
                      </th>
                      <th style="cursor:pointer;min-width: 100px;" @click="sortBy('asset-class')" v-if="statColumns.assetClass.visible">
                        <span class="mr-1">{{$t('tables.indices.columns.assetClass')}}</span>
                        <span class="float-right" v-if="criteria.sortBy === 'asset-class'">
                          <span v-if="criteria.sortByDir === 'asc'">
                            <i class="fa  fas fa-sort-up"></i>
                          </span>
                          <span v-if="criteria.sortByDir === 'desc'">
                            <i class="fa  fas fa-sort-down"></i>
                          </span>
                        </span>
                      </th>
                      <th style="cursor:pointer;min-width: 100px;white-space: normal;" @click="sortBy('instrument-type')" v-if="statColumns.instrumentType.visible">
                        <span class="mr-1">{{$t('tables.indices.columns.instrumentType')}}</span>
                        <span class="float-right" v-if="criteria.sortBy === 'instrument-type'">
                          <span v-if="criteria.sortByDir === 'asc'">
                            <i class="fa  fas fa-sort-up"></i>
                          </span>
                          <span v-if="criteria.sortByDir === 'desc'">
                            <i class="fa  fas fa-sort-down"></i>
                          </span>
                        </span>
                      </th>
                      <th style="cursor:pointer;min-width: 100px;white-space: normal;" @click="sortBy('rebalance-frequency')" v-if="statColumns.rebalanceFrequency.visible">
                        <span class="mr-1">{{$t('tables.indices.columns.rebalanceFrequency')}}</span>
                        <span class="float-right" v-if="criteria.sortBy === 'rebalance-frequency'">
                          <span v-if="criteria.sortByDir === 'asc'">
                            <i class="fa  fas fa-sort-up"></i>
                          </span>
                          <span v-if="criteria.sortByDir === 'desc'">
                            <i class="fa  fas fa-sort-down"></i>
                          </span>
                        </span>
                      </th>
                      <th style="cursor:pointer;" @click="sortBy('ytd')" v-if="statColumns.ytd.visible">
                        <span class="mr-1">{{$t('tables.indices.columns.ytd')}}</span>
                        <span class="float-right" v-if="criteria.sortBy === 'ytd'" >
                          <span v-if="criteria.sortByDir === 'asc'">
                            <i class="fa  fas fa-sort-up"></i>
                          </span>
                          <span v-if="criteria.sortByDir === 'desc'">
                            <i class="fa  fas fa-sort-down"></i>
                          </span>
                        </span>
                      </th>
                      <th style="cursor:pointer;" @click="sortBy('mtd')" v-if="statColumns.mtd.visible">
                        <span class="mr-1">{{$t('tables.indices.columns.mtd')}}</span>
                        <span class="float-right" v-if="criteria.sortBy === 'mtd'" >
                          <span v-if="criteria.sortByDir === 'asc'">
                            <i class="fa  fas fa-sort-up"></i>
                          </span>
                          <span v-if="criteria.sortByDir === 'desc'">
                            <i class="fa  fas fa-sort-down"></i>
                          </span>
                        </span>
                      </th>
                        <th style="cursor:pointer;" @click="sortBy('stat-updated')" v-if="statColumns.statUpdatedAt.visible">
                        <span class="mr-1">Updated At</span>
                        <span class="float-right" v-if="criteria.sortBy === 'stat-updated'" >
                          <span v-if="criteria.sortByDir === 'asc'">
                            <i class="fa  fas fa-sort-up"></i>
                          </span>
                          <span v-if="criteria.sortByDir === 'desc'">
                            <i class="fa  fas fa-sort-down"></i>
                          </span>
                        </span>
                      </th>
                      <th style="cursor:pointer;max-width:90px;white-space: normal;" @click="sortBy('annualised-return')" v-if="statColumns.annualisedReturn.visible">
                        <span class="mr-1">{{$t('tables.indices.columns.annualisedReturn')}}</span>
                        <span class="float-right" v-if="criteria.sortBy === 'annualised-return'" >
                          <span v-if="criteria.sortByDir === 'asc'">
                            <i class="fa  fas fa-sort-up"></i>
                          </span>
                          <span v-if="criteria.sortByDir === 'desc'">
                            <i class="fa  fas fa-sort-down"></i>
                          </span>
                        </span>
                      </th>
                      <th style="cursor:pointer;max-width:100px;min-width:100px;white-space: normal;" @click="sortBy('realised-volatility')" v-if="statColumns.realisedVolatility.visible">
                        <span class="mr-1">{{$t('tables.indices.columns.realisedVolatility')}}</span>
                        <span class="float-right" v-if="criteria.sortBy === 'realised-volatility'" >
                          <span v-if="criteria.sortByDir === 'asc'">
                            <i class="fa  fas fa-sort-up"></i>
                          </span>
                          <span v-if="criteria.sortByDir === 'desc'">
                            <i class="fa  fas fa-sort-down"></i>
                          </span>
                        </span>
                      </th>
                      <th  style="cursor:pointer;max-width:110px;min-width:110px;white-space: normal;;"  @click="sortBy('downside-volatility')" v-if="statColumns.downsideVolatility.visible">
                        <span class="mr-1">{{$t('tables.indices.columns.downsideVolatility')}}</span>
                        <span class="float-right" v-if="criteria.sortBy === 'downside-volatility'" >
                          <span v-if="criteria.sortByDir === 'asc'">
                            <i class="fa  fas fa-sort-up"></i>
                          </span>
                          <span v-if="criteria.sortByDir === 'desc'">
                            <i class="fa  fas fa-sort-down"></i>
                          </span>
                        </span>
                      </th>
                      <th style="cursor:pointer;max-width:110px;min-width:110px;white-space: normal;" @click="sortBy('max-drawdown')" v-if="statColumns.maxDrawdown.visible">
                        <span class="mr-1">{{$t('tables.indices.columns.maxDrawdown')}}</span>
                        <span class="float-right" v-if="criteria.sortBy === 'max-drawdown'" >
                          <span v-if="criteria.sortByDir === 'asc'">
                            <i class="fa  fas fa-sort-up"></i>
                          </span>
                          <span v-if="criteria.sortByDir === 'desc'">
                            <i class="fa  fas fa-sort-down"></i>
                          </span>
                        </span>
                      </th>
                      <th style="cursor:pointer;max-width:110px;min-width:110px;white-space: normal;" @click="sortBy('winning-months')" v-if="statColumns.winningMonths.visible">
                        <span class="mr-1">{{$t('tables.indices.columns.winningMonths')}}</span>
                        <span class="float-right" v-if="criteria.sortBy === 'winning-months'" >
                          <span v-if="criteria.sortByDir === 'asc'">
                            <i class="fa  fas fa-sort-up"></i>
                          </span>
                          <span v-if="criteria.sortByDir === 'desc'">
                            <i class="fa  fas fa-sort-down"></i>
                          </span>
                        </span>
                      </th>
                      <th style="cursor:pointer;max-width:90px;min-width:90px;white-space: normal;" @click="sortBy('sharpe-ratio')" v-if="statColumns.sharpeRatio.visible">
                        <span class="mr-1">{{$t('tables.indices.columns.sharpeRatio')}}</span>
                        <span class="float-right" v-if="criteria.sortBy === 'sharpe-ratio'" >
                          <span v-if="criteria.sortByDir === 'asc'">
                            <i class="fa  fas fa-sort-up"></i>
                          </span>
                          <span v-if="criteria.sortByDir === 'desc'">
                            <i class="fa  fas fa-sort-down"></i>
                          </span>
                        </span>
                      </th>
                      <th style="cursor:pointer;max-width:90px;min-width:90px;white-space: normal;" @click="sortBy('sortino-ratio')" v-if="statColumns.sortinoRatio.visible">
                        <span class="mr-1">{{$t('tables.indices.columns.sortinoRatio')}}</span>
                        <span class="float-right" v-if="criteria.sortBy === 'sortino-ratio'" >
                          <span v-if="criteria.sortByDir === 'asc'">
                            <i class="fa  fas fa-sort-up"></i>
                          </span>
                          <span v-if="criteria.sortByDir === 'desc'">
                            <i class="fa  fas fa-sort-down"></i>
                          </span>
                        </span>
                      </th>
                      <th style="cursor:pointer;max-width:90px;min-width:90px;white-space: normal;" @click="sortBy('calmar-ratio')" v-if="statColumns.calmarRatio.visible">
                        <span class="mr-1">{{$t('tables.indices.columns.calmarRatio')}}</span>
                        <span class="float-right" v-if="criteria.sortBy === 'calmar-ratio'" >
                          <span v-if="criteria.sortByDir === 'asc'">
                            <i class="fa  fas fa-sort-up"></i>
                          </span>
                          <span v-if="criteria.sortByDir === 'desc'">
                            <i class="fa  fas fa-sort-down"></i>
                          </span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in filteredItems" :key="item.id">
                      <td class="text-left favorites" style="width:50px; min-width:50px;">
                        
                        <span> 
                          <button class="btn  btn-sm btn-c8" v-if="!composeIndexItems.find(a => a.id === item.id)" @click="selectComposeIndexPart(item)">
                            <span class="btn-content">
                              Select
                            </span>
                          </button>
                        </span>
                        
                      </td>
                      
                      <td style="min-width:250px;width:300px;" >
                        <strong style="white-space: initial;">
                          <router-link :to="'indices/' + item.id + '/view'" class="text-dark">{{item.displayName}}</router-link>
                        </strong>
                        <div v-if="item.optimization && item.optimization.solutionTitle">
                          <small class="text-muted">
                            Solution: {{item.optimization.solutionTitle}}
                          </small>
                        </div>
                      </td>
                      <td v-if="statColumns.indexType.visible">{{(item.indexType || {}).displayName}}</td>
                      <td v-if="statColumns.indexSubType.visible">{{(item.indexSubType || {}).displayName}}</td>
                      <td v-if="statColumns.market.visible">{{(item.market || {}).displayName}}</td>
                      <td v-if="statColumns.provider.visible">{{(item.provider || {}).displayName}}</td>
                      <td v-if="statColumns.assetClass.visible">{{assetClassMap[item.assetClass || {}]}}</td>
                      <td v-if="statColumns.instrumentType.visible">{{instrumentTypeMap[item.instrumentType || {}]}}</td>
                      <td v-if="statColumns.rebalanceFrequency.visible">{{rebalanceFrequencyMap[item.rebalanceFrequency || {}]}}</td>
                    
                      <td v-if="statColumns.ytd.visible">
                        {{item.performance.ytdFormatted}} <span v-if="item.performance.ytdFormatted">%</span>
                      </td>
                      <td v-if="statColumns.mtd.visible">
                        {{item.performance.mtdFormatted}} <span  v-if="item.performance.mtdFormatted">%</span>
                      </td>
                      <td v-if="statColumns.statUpdatedAt.visible">
                        {{item.statUpdatedAt | moment('DD/MM/YYYY')}} <span  v-if="item.statUpdatedAt"></span>
                      </td>
                      <td v-if="statColumns.annualisedReturn.visible">
                      <span v-if="item.statistic">
                          {{item.statistic.values.annualisedReturnFormatted}} <span  v-if="item.statistic.values.annualisedReturnFormatted">%</span>
                      </span>
                      </td>
                      <td v-if="statColumns.realisedVolatility.visible">
                      <span v-if="item.statistic">
                          {{item.statistic.values.realisedVolatilityFormatted}} <span  v-if="item.statistic.values.realisedVolatilityFormatted">%</span>
                      </span>
                      </td>
                      <td v-if="statColumns.downsideVolatility.visible">
                      <span v-if="item.statistic">
                          {{item.statistic.values.downsideVolatilityFormatted}} <span  v-if="item.statistic.values.downsideVolatilityFormatted">%</span>
                      </span>
                      </td>
                    
                      <td v-if="statColumns.maxDrawdown.visible">
                      <span v-if="item.statistic">
                          {{item.statistic.values.maxDrawdownFormatted}} <span  v-if="item.statistic.values.maxDrawdownFormatted">%</span>
                      </span>
                      </td>
                      <td v-if="statColumns.winningMonths.visible">
                      <span v-if="item.statistic">
                          {{item.statistic.values.winningMonthsFormatted}} <span  v-if="item.statistic.values.winningMonthsFormatted">%</span>
                      </span>
                      </td>
                      <td v-if="statColumns.sharpeRatio.visible">
                      <span v-if="item.statistic">
                          {{item.statistic.values.sharpeRatioFormatted}} 
                      </span>
                      </td>
                      <td v-if="statColumns.sortinoRatio.visible">
                      <span v-if="item.statistic">
                          {{item.statistic.values.sortinoRatioFormatted}} 
                      </span>
                      </td>
                      <td v-if="statColumns.calmarRatio.visible">
                      <span v-if="item.statistic">
                          {{item.statistic.values.calmarRatioFormatted}} 
                      </span>
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              
              </div>

            </div>   
          </div>
        </div>
        <div class="indices-table-footer">
          <div class="">
            <div class="d-flex justify-content-md-between align-items-md-center">
              <div >Total: {{totalItems}}</div>
              <div >
                <select class="form-control input-sm" v-model="perPage">
                  <option :value="20">20 records</option>
                  <option :value="50">50 records</option>
                  <option :value="100">100 records</option>  
                </select>
              </div>
              <div >
                <div class="text-md-right float-xl-right mt-2 ">
                
                  <b-pagination v-if="!initializing"
                    v-model="currentPage"
                    :total-rows="totalItems"
                    :per-page="perPage"
                    :limit="3"
                  ></b-pagination>
                </div>
              </div>
          

            </div>
          </div>
          
        </div>
      </div>

      <div class="col-xl-3">
        <div  style="">
         
          <div class="alert alert-info" v-if="composeIndexItems.length === 0">Please select indices below.</div>
          <div>
          
          <table class="table table-striped table-sm table-hover" >
            <tbody>
              <tr v-for="i in composeIndexItems" :key="i.id">
                <td>{{i.displayName}}</td>
                <td style="max-width:30px; width:30px;">
                  <a href="javascript:void(0)" @click="removeComposeIndexPart(i)">
                    <i class="fa fa-times"/>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>

       
      </div>

    </div>

    <div class="buttons">
      <div class="row">
        <div class="col px-2">
          <div class="btn-group mx-2 float-left" >
            <button type="button"  class="btn btn-lg btn-outline-secondary  mb-2"  @click="cancel">Cancel</button>
          </div>
          <div class="btn-group mx-2 float-right" >
            <button type="button"   class="btn btn-lg btn-primary mb-2" @click="apply"> Apply</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>